import type { Display, MappingJSONItem } from '@/types/mappingJSON';
import type {
  FieldOfScience,
  LumiScienceAreas,
  ScienceAreas,
} from '@/types/scienceArea';
import type { DataResponse } from '~/types';

export const useScienceAreaStore = defineComposableStore('scienceArea', () => {
  const scienceAreas = ref<ScienceAreas>({
    fieldOfScience: [],
    subFieldOfScience: [],
    secondSubFieldOfScience: [],
  });

  const lumiScienceAreas = ref<LumiScienceAreas>({
    fieldOfScience: [],
  });

  const scienceAreaMap = reactive<{
    default: Record<string, string>;
    lumi: Record<string, string>;
  }>({
    default: {},
    lumi: {},
  });

  const { mappingJSONError } = useProjectStore();

  const { startLoading, endLoading } = useLoadingStore();

  const getLabel = (row: MappingJSONItem) =>
    Array.isArray(row.display)
      ? row.display.find((d) => d['xml:lang'] === 'en')?.label || ''
      : row.display.label;

  const updateScienceAreaMap = (data: MappingJSONItem[]) =>
    data.reduce(
      (items, row) => ({
        ...items,
        [row.key]: getLabel(row),
      }),
      {},
    );

  const getScienceAreas = async () => {
    if (
      scienceAreas.value.fieldOfScience.length &&
      lumiScienceAreas.value.fieldOfScience.length
    )
      return;

    mappingJSONError.value = false;

    startLoading('getScienceArea');

    const [{ data }, { data: lumiData }] = await Promise.all([
      api.get<DataResponse<MappingJSONItem[]>>(
        '/api/ldap/json-map?list=CSCScienceArea,CSCScienceAreaBase',
      ),
      api.get<DataResponse<MappingJSONItem[]>>(
        '/api/ldap/json-map?list=PuhuriScienceArea',
      ),
    ]);

    if (data?.length) {
      scienceAreas.value = generateScienceAreas(data);
      scienceAreaMap.default = updateScienceAreaMap(data);
    }

    if (lumiData?.length) {
      lumiScienceAreas.value = generateLumiScienceAreas(lumiData);
      scienceAreaMap.lumi = updateScienceAreaMap(lumiData);
    }

    endLoading('getScienceArea');
  };

  const mapScienceAreas = (items: MappingJSONItem[]) => {
    return items.map((item) => {
      const options = {
        value: item.key.toString(),
      };

      const parentCodeLength = item.key.toString().length === 4 ? 3 : 1;

      return {
        ...options,
        parentCode: item.key.toString().substring(0, parentCodeLength),
        name:
          (item.display as Display[]).find((x) => x['xml:lang'] === 'en')
            ?.label || '',
      };
    });
  };

  const mapLumiScienceAreas = (items: MappingJSONItem[]) => {
    return items.map((item) => {
      const options = {
        value: item.key.toString(),
      };

      return {
        ...options,
        name: `${item.key} ${(item.display as Display).label}`,
      };
    });
  };

  const generateScienceAreas = (items: MappingJSONItem[]) => {
    return mapScienceAreas(items).reduce<ScienceAreas>(
      (areas: ScienceAreas, item: FieldOfScience) => {
        if (item.value.length < 3) {
          areas.fieldOfScience.push(item);
        }

        if (item.value.length === 3) {
          areas.subFieldOfScience.push(item);
        }

        if (item.value.length === 4) {
          areas.secondSubFieldOfScience.push(item);
        }

        return areas;
      },
      {
        fieldOfScience: [],
        subFieldOfScience: [],
        secondSubFieldOfScience: [],
      },
    );
  };

  const generateLumiScienceAreas = (items: MappingJSONItem[]) => {
    return mapLumiScienceAreas(items).reduce<LumiScienceAreas>(
      (areas: LumiScienceAreas, item: FieldOfScience) => {
        areas.fieldOfScience.push(item);

        return areas;
      },
      {
        fieldOfScience: [],
      },
    );
  };

  const populateScienceAreas = (areaCode: string) => {
    if (!areaCode) {
      return {
        fieldOfScience: '',
        subFieldOfScience: '',
        secondSubFieldOfScience: '',
      };
    }

    const scienceAreaCode = areaCode?.[0];

    const subAreaCode = areaCode.length >= 3 ? areaCode.substring(0, 3) : '';

    const subSubAreaCode = areaCode?.length === 4 ? areaCode : '';

    const field: FieldOfScience | null =
      scienceAreas.value.fieldOfScience.find(
        (area) => +area.value === +scienceAreaCode,
      ) ?? null;

    const subField: FieldOfScience | null =
      scienceAreas.value.subFieldOfScience.find(
        (area) => +area.value === +subAreaCode,
      ) ?? null;

    const subSubField: FieldOfScience | null =
      scienceAreas.value.secondSubFieldOfScience.find(
        (area) => +area.value === +subSubAreaCode,
      ) ?? null;

    return {
      fieldOfScience: field?.value || '',
      subFieldOfScience: subField?.value || '',
      secondSubFieldOfScience: subSubField?.value || '',
    };
  };

  return {
    getScienceAreas,
    populateScienceAreas,
    scienceAreaMap,
    scienceAreas,
    lumiScienceAreas,
  };
});
