import { Temporal } from '@js-temporal/polyfill';
import type { MaintenanceResult, MaintenanceStatus } from '@/types/maintenance';

export const useMaintenance = () => {
  const maintenanceStatus = ref<MaintenanceStatus>({} as MaintenanceStatus);

  const showMaintenanceDialog = computed(() => {
    if (!maintenanceStatus.value?.advanceTime) {
      return {
        maintenance: false,
        notification: false,
      };
    }

    const startISO = toIsoString(new Date(maintenanceStatus.value.start));
    const endISO = toIsoString(new Date(maintenanceStatus.value.end));

    const today = Temporal.Now.plainDateTimeISO();
    const startDate = Temporal.PlainDateTime.from(startISO);
    const endDate = Temporal.PlainDateTime.from(endISO);
    const notificationStart = startDate.subtract({
      days: maintenanceStatus.value.advanceTime,
    });

    const isInNotificationPeriod =
      // today is the notification start date or later
      Temporal.PlainDateTime.compare(notificationStart, today) <= 0 &&
      // ...and earlier than the start date
      Temporal.PlainDateTime.compare(today, startDate) === -1;

    const isInMaintenancePeriod =
      // today is the maintenance start date or later
      Temporal.PlainDateTime.compare(startDate, today) <= 0 &&
      // ...and earlier than the maintenance end date
      Temporal.PlainDateTime.compare(today, endDate) <= 0;

    return {
      maintenance: isInMaintenancePeriod,
      notification: isInNotificationPeriod,
    };
  });

  const getMaintenanceStatus = async () => {
    const url = `/api/allas/maintenance?environment=${import.meta.env.MODE}`;

    const { data } = await api.get<MaintenanceResult>(url);

    maintenanceStatus.value = data ?? ({} as MaintenanceStatus);
  };

  return {
    maintenanceStatus,
    getMaintenanceStatus,
    showMaintenanceDialog,
  };
};
