import { create, test, enforce } from 'vest';
import { isRequiredMessage } from '@/utils/validationMessages';

export const accountExtensionRequestSuite = create((data) => {
  test('project', isRequiredMessage(), () => {
    enforce(data.selectedProject).isNotBlank();
  });

  test('reason', isRequiredMessage(), () => {
    enforce(data.reason).isNotBlank();
  });
});

export default accountExtensionRequestSuite;
