import { Dialogs } from '~/types/enum';

export default defineNuxtRouteMiddleware((to) => {
  if (['Buc', 'Login', 'Root', 'OIDCLogin'].includes(to.name as string)) return;

  if (!localStorage.getItem('consent')) {
    const { addDialog } = useDialogStore();

    requestAnimationFrame(() => {
      addDialog(Dialogs.CookieConsent, true);
    });
  }
});
