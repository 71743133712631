type Loader = {
  active: boolean;
  message?: string;
};

export const useLoadingStore = defineComposableStore('loading', () => {
  const loadErrors = ref<Set<string>>(new Set([]));

  const loaders = ref<Map<string, Loader>>(new Map());

  const startLoading = (name: string, message?: string) => {
    loaders.value.set(name, { active: true, message });
  };

  const endLoading = (name: string) => {
    loaders.value.delete(name);
  };

  const isLoading = (name: string | string[]) => {
    return Array.isArray(name)
      ? name.some((item) => loaders.value.has(item))
      : loaders.value.has(name as string);
  };

  const isAnyLoading = () => {
    return loaders.value.size > 0;
  };

  const getLoadingMessage = (name: string) => loaders.value.get(name)?.message;

  return {
    loadErrors,
    startLoading,
    endLoading,
    isLoading,
    isAnyLoading,
    getLoadingMessage,
  };
});
