import type { DocumentNode } from 'graphql';
import { useQuery, createClient, CombinedError } from 'villus';
import { CToastType } from '@cscfi/csc-ui';
import type { DataResponse } from '~/types';

export const getReppuToken = async () => {
  const response = await api.get<DataResponse<string>>(
    '/api/authentication/reppu-token',
  );

  if (response.success && response.data) {
    sessionStorage.setItem('reppu-token', response.data);
  }
};

type ReppuComposable = <T = any>() => {
  error: Ref<CombinedError | null>;
  response: Ref<T>;
  query: (queryString: DocumentNode, variables?: {}) => Promise<void>;
};

export const useGqlApi: ReppuComposable = () => {
  const { reppuApiService } = useRuntimeConfig().public;

  const client = createClient({
    url: reppuApiService,
  });

  const response = ref();
  const error = ref<CombinedError | null>(null);

  let tokenRetries = 0;

  const query = async (queryString: DocumentNode, variables = {}) => {
    if (!sessionStorage.getItem('reppu-token')) {
      await getReppuToken();
    }

    error.value = null;

    const { loadErrors } = useLoadingStore();

    loadErrors.value.delete('reppuError');

    const token = sessionStorage.getItem('reppu-token');

    try {
      const { data, error: queryError } = await useQuery({
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        query: queryString,
        variables,
        client,
      });

      // refresh token in case of a 403 error
      if (queryError.value?.response.status === 403) {
        throw new Error('Token expired');
      } else if (queryError.value?.message.includes('{"status": -4}')) {
        const { addNotification } = useNotificationStore();

        addNotification(`create-resource-application`, {
          title: 'The application is not possible',
          message: 'The Project Manager account is inactive.',
          type: CToastType.Error,
          persistent: true,
        });
      } else if (queryError.value) {
        loadErrors.value.add('reppuError');
      }

      tokenRetries = 0;

      response.value = data.value;
    } catch (e) {
      console.error(e);
      tokenRetries += 1;

      if (tokenRetries < 3) {
        // remove the expired token
        sessionStorage.removeItem('reppu-token');

        await getReppuToken();
        await query(queryString, variables);
      } else {
        loadErrors.value.add('reppuError');
      }
    }
  };

  return { error, response, query };
};
