<template>
  <div>
    <nuxt-layout>
      <nuxt-page />
    </nuxt-layout>

    <teleport to="body">
      <transition name="fade" mode="out-in">
        <loader
          v-if="isLoading(['fetchingData'])"
          data-test="loading"
          class="z-[200001]"
        >
          <template #title>Please wait</template>

          <template #subtitle>
            Loading {{ isLoading('projects') ? 'projects' : 'user profile' }}
          </template>
        </loader>
      </transition>

      <c-toasts id="toasts" ref="toasts" horizontal="center" />

      <dialog-handler />
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import { defineCustomElements, type CToastMessage } from '@cscfi/csc-ui/loader';
import { Dialogs } from './types/enum';

useHead({
  titleTemplate: (title) => `${title} - My CSC`,
  htmlAttrs: { lang: 'en-US' },
  link: [{ rel: 'icon', type: 'image/png', href: '/favicon.png' }],
});

defineCustomElements();

const { height } = useWindowSize();

const { maintenanceStatus, getMaintenanceStatus, showMaintenanceDialog } =
  useMaintenance();

const { isLoading } = useLoadingStore();

const { addDialog } = useDialogStore();

watch(showMaintenanceDialog, (value) => {
  if (value.maintenance || value.notification) {
    addDialog(Dialogs.MaintenanceBreak, true, {
      data: {
        ...value,
        ...maintenanceStatus.value,
      },
    });
  }
});

let ws: WebSocket | undefined;

const connectToWs = async () => {
  const config = useRuntimeConfig();

  const url = config.public.messageServiceWss;

  if (ws) {
    ws.close();
  }

  ws = new WebSocket(url);

  await new Promise((resolve) => ws!.addEventListener('open', resolve));
};

const { notifications, removeNotification } = useNotificationStore();

watch(
  notifications,
  (toastMessages: Map<string, CToastMessage>) => {
    if (!toastMessages.size) return;

    const messages = toastMessages.entries();

    const nextMessage = messages.next();

    if (!nextMessage.value) return;

    const [name, message] = nextMessage.value;

    [...document.querySelectorAll('c-toasts')].forEach((toasts) => {
      toasts.addToast({ ...message, custom: false });
    });

    removeNotification(name);
  },
  { deep: true },
);

watch(
  height,
  () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  { immediate: true },
);

onMounted(async () => {
  await getMaintenanceStatus();

  await connectToWs();
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}
</style>
