import { create, test, enforce, only } from 'vest';
import { isRequiredMessage } from '@/utils/validationMessages';

export const emailSuite = create((data, name?: string) => {
  only(name);

  test('email', isRequiredMessage(), () => {
    enforce(data.email).isNotBlank();
  });

  test('recaptcha', isRequiredMessage(), () => {
    enforce(data.recaptchaResponse).isNotBlank();
  });
});

export default emailSuite;
