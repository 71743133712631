import type { StoreGeneric } from 'pinia';
import type { ToRefs } from 'vue';

// Extrancted from "utility-types" package
type NonUndefined<A> = A extends undefined ? never : A;

type FunctionKeys<T extends object> = {
  [K in keyof T]-?: NonUndefined<T[K]> extends Function ? K : never;
}[keyof T];

type NonFunctionKeys<T extends object> = {
  [K in keyof T]-?: NonUndefined<T[K]> extends Function ? never : K;
}[keyof T];

/**
@description Creates a defineComposableStore function that retrieves the store instance
@param id — id of the store (must be unique)
@param storeSetup — function that defines the store
 */
export function defineComposableStore<Id extends string, SS>(
  id: Id,
  storeSetup: () => SS,
  pluginOptions?: Record<string, unknown>,
) {
  const piniaStore = defineStore(id, storeSetup, pluginOptions);

  return () => {
    const store = piniaStore();
    const storeRefs = storeToRefs(store as StoreGeneric);

    // Pick only function properties from source store.
    // And append refs to final output.
    type StoreFunctions = Pick<typeof store, FunctionKeys<typeof store>> &
      NonNullable<unknown>;

    type NonFunctions = ToRefs<
      Pick<typeof store, NonFunctionKeys<typeof store>>
    >;

    type OmittedValues = Omit<
      NonFunctions,
      '_customProperties' | '$state' | '$id'
    >;

    type OmittedFunctions = Omit<
      StoreFunctions,
      '$onAction' | '$patch' | '$reset' | '$subscribe'
    >;

    return { ...(store as OmittedFunctions), ...(storeRefs as OmittedValues) };
  };
}
