type DialogProps = Record<string, unknown>;

type Dialog = {
  component: string;
  props?: DialogProps;
};

export const useDialogStore = defineComposableStore('dialog', () => {
  const dialogs = ref<Dialog[]>([]);

  const priorityDialogs = ref<Dialog[]>([]);

  const currentDialog = computed(() => {
    return dialogs.value[0] || null;
  });

  const priorityDialog = computed(() => {
    return priorityDialogs.value[0] || null;
  });

  const isDialogOpen = (dialog: string) =>
    currentDialog.value?.component === dialog;

  const addDialog = (
    component: string,
    priority = false,
    props: DialogProps = {},
  ) => {
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);

    const dialog: Dialog = {
      component,
      props: {
        ...props,
        id,
      },
    };

    (priority ? priorityDialogs : dialogs).value.push(dialog);
  };

  const removeDialog = async (id: string) => {
    // wait for the dialog animation to finish
    await pause(300);

    const isPriorityDialog = priorityDialogs.value.find(
      (dialog) => dialog.props!.id === id,
    );

    if (isPriorityDialog) {
      priorityDialogs.value = priorityDialogs.value.filter(
        (dialog) => dialog.props!.id !== id,
      );

      return;
    }

    dialogs.value = dialogs.value.filter((dialog) => dialog.props!.id !== id);
  };

  return {
    dialogs,
    priorityDialogs,
    addDialog,
    removeDialog,
    isDialogOpen,
    currentDialog,
    priorityDialog,
  };
});
