import { create, test, enforce } from 'vest';
import { isRequiredMessage } from '@/utils/validationMessages';

export const idaApprovalSuite = create((size) => {
  test('size', isRequiredMessage(), () => {
    enforce(size).isNotBlank();
  });

  test('size', isRequiredMessage(), () => {
    enforce(size).isNotEmpty();
  });

  test('size', 'Value must be a positive integer', () => {
    enforce(size).greaterThan(0);
  });
});

export default idaApprovalSuite;
