import { create, test, enforce, only } from 'vest';
import {
  isRequiredMessage,
  isPositiveMessage,
} from '@/utils/validationMessages';

export const resourceApplicationSuite = create((data, name?: string) => {
  only(name);

  test('grantedBUs', isRequiredMessage(), () => {
    enforce(data.grantedBUs).isNotBlank();
  });

  test('grantedBUs', isPositiveMessage(), () => {
    enforce(+data.grantedBUs).isNumeric();
  });
});

export default resourceApplicationSuite;
