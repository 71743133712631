import { create, test, enforce, only } from 'vest';
import { isRequiredMessage } from '@/utils/validationMessages';

export const feedbackSuite = create((data, name?: string) => {
  only(name);

  test('message', isRequiredMessage(), () => {
    enforce(data.message).isNotBlank();
  });
});

export default feedbackSuite;
