import { create, test, enforce, only, omitWhen } from 'vest';
import {
  isRequiredMessage,
  isGreaterThanMessage,
  isLessThanOrEqualsMessage,
} from '@/utils/validationMessages';

export const allasQuotaSuite = create((data = {}, name?: string) => {
  only(name);

  test('quota', isRequiredMessage(), () => {
    enforce(data.quota).isNotBlank();
  });

  test('quota', isGreaterThanMessage(data.used), () => {
    enforce(+data.quota).greaterThan(data.used);
  });

  test('quota', isLessThanOrEqualsMessage(200), () => {
    enforce(+data.quota).lessThanOrEquals(200);
  });

  omitWhen(!data.isJustificationRequired, () => {
    test('justification', isRequiredMessage(), () => {
      enforce(data.justification).isNotBlank();
    });
  });
});

export default allasQuotaSuite;
