export class BreadcrumbItem {
  constructor(
    public label: string,
    public name: string,
    public disabled = false,
    public routeParam = '',
  ) {}
}

export type Breadcrumb = typeof BreadcrumbItem.prototype;
