export const convertLDAPDate = (value: string | string[]): Date => {
  const [ldapString] = [value].flat();

  if (!ldapString) return new Date();

  const b = ldapString.match(/\d\d/g)!;

  return new Date(
    Date.UTC(+(b[0] + b[1]), +b[2] - 1, +b[3], +b[4], +b[5], +b[6]),
  );
};
