import type { RouteLocationNormalized } from 'vue-router';
import type { DataPreloadHandler } from '~/types/middleware';

export const preloadManagerStatus: DataPreloadHandler = async (
  to: RouteLocationNormalized,
) => {
  const { getProjectManagerStatus } = useProjectStore();

  if (to.params.projectNumber) {
    await getProjectManagerStatus(to.params.projectNumber as string);
  }
};
