import { create, test, enforce, only } from 'vest';
import {
  isLongerThanOrEqualsMessage,
  isRequiredMessage,
  isShorterThanOrEqualsMessage,
} from '@/utils/validationMessages';

const matchingPasswordsMessage = () => 'The passwords do not match';
const notContainsMessage = () => 'The value can not contain your name';
const notContainsCnMessage = () => 'The value can not contain your username';
const containsRequiredCharactersMessage = () =>
  'The value must contain at least one lowercase character, at least one uppercase character and at least one number';
const doesNotContainInvalidCharactersMessage = () =>
  'The password contains invalid characters';

export const passwordChangeSuite = create((data, name?: string) => {
  only(name);

  test('currentPassword', isRequiredMessage(), () => {
    enforce(data.currentPassword).isNotBlank();
  });

  test('newPassword', isRequiredMessage(), () => {
    enforce(data.newPassword).isNotBlank();
  });

  test('newPassword', isLongerThanOrEqualsMessage(12), () => {
    enforce(data.newPassword).longerThanOrEquals(12);
  });

  test('newPassword', isShorterThanOrEqualsMessage(120, 'password'), () => {
    enforce(data.newPassword).shorterThanOrEquals(120);
  });

  test('newPassword', containsRequiredCharactersMessage(), () => {
    enforce(data.newPassword)
      .matches(/[a-z]{1,}/)
      .matches(/[A-Z]{1,}/)
      .matches(/[0-9]{1,}/);
  });

  test('newPassword', doesNotContainInvalidCharactersMessage(), () => {
    const forbiddenCharacters = ['½', '\\', '`', '¤', '"', "'", '<'];

    const hasForbiddenCharacters = forbiddenCharacters.some((character) =>
      data.newPassword.includes(character),
    );

    enforce(hasForbiddenCharacters).equals(false);

    // eslint-disable-next-line no-control-regex
    enforce(data.newPassword).notMatches(/[^\x00-\x7F]/);
  });

  test('newPassword', notContainsCnMessage(), () => {
    enforce(data.profile.cn).notInside(data.newPassword.toLowerCase());
  });

  test('newPassword', notContainsMessage(), () => {
    enforce(data.profile.sn.toLowerCase()).notInside(
      data.newPassword.toLowerCase(),
    );

    data.profile.givenName.split(' ').forEach((firstName: string) => {
      enforce(firstName.toLowerCase()).notInside(
        data.newPassword.toLowerCase(),
      );
    });
  });

  test('confirmPassword', isRequiredMessage(), () => {
    enforce(data.confirmPassword).isNotBlank();
  });

  test('confirmPassword', matchingPasswordsMessage(), () => {
    enforce(data.confirmPassword).equals(data.newPassword);
  });
});

export default passwordChangeSuite;
