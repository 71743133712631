import { create, test, enforce } from 'vest';

export const idaQuotaSuite = create((data = {}) => {
  test('newQuota', 'New total quota is required', () => {
    enforce(data.newQuota).isNotBlank();
  });

  test('newQuota', 'New total quota must be an integer', () => {
    enforce(+data.newQuota).isNumeric();

    enforce(+data.newQuota % 1).equals(0);
  });

  test('newQuota', 'New total quota must be bigger than usage', () => {
    enforce(data.newQuota).greaterThan(data.currentUsage);
  });
});

export default idaQuotaSuite;
