import { create, test, enforce, only, omitWhen } from 'vest';
import { isRequiredMessage } from '@/utils/validationMessages';

export const projectPresentationAddSuite = create((data, name?: string) => {
  only(name);

  omitWhen(!data.admin, () => {
    test('projectNumber', isRequiredMessage(), () => {
      enforce(data.projectNumber).isNotEmpty();
    });

    test('createdAt', isRequiredMessage(), () => {
      enforce(data.createdAt).isNotEmpty();
    });
  });
});
