import { createSentryInstance } from '~/sentry';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  if (
    !config.public.SENTRY_DSN_PUBLIC ||
    process.env.NODE_ENV === 'development'
  ) {
    return;
  }

  createSentryInstance(nuxtApp.vueApp, router, config.public.SENTRY_DSN_PUBLIC);
});
