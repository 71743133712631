import type { Pinia, PiniaPluginContext } from 'pinia';
import clone from 'clone';

export const resetStore = ({ store }: PiniaPluginContext) => {
  const initialState = clone(store.$state);

  store.$reset = () => {
    store.$patch(clone(initialState));
  };
};

export default defineNuxtPlugin(({ $pinia }) => {
  const _pinia = $pinia as Pinia;

  if (!_pinia) return;

  _pinia.use(resetStore);
});
