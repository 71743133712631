const createRoleColors = (color: string, shade = 600) => {
  return {
    color: `var(--${color}-${shade})`,
    hoverColor: `var(--${color}-100)`,
    hoverColorActive: `var(--${color}-${shade - 100})`,
  };
};

export const funetRoles = {
  CSCOrgFunSecContactDN: {
    name: 'Security',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('csc-color-3'),
  },
  CSCOrgFunCertContactDN: {
    name: 'Cert',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('csc-color-4'),
  },
  CSCOrgFunMiittiContactDN: {
    name: 'Miitti',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('csc-color-6'),
  },
  CSCOrgFunNocContactDN: {
    name: 'Noc',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('csc-color-7', 500),
  },
  CSCOrgFunAdmContactDN: {
    name: 'Admin',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('csc-color-8'),
  },
  CSCOrgFunCertMainContactDN: {
    name: 'Cert Main',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('c-link', 400),
  },
  CSCOrgFunTechContactDN: {
    name: 'Tech',
    badge: 'Funet',
    assignable: true,
    ...createRoleColors('c-info'),
  },
};

export const organizationRoles = {
  ...funetRoles,
  CSCOrgIdaContactPerson: {
    badge: 'IDA',
    name: 'Admin',
    assignable: false,
    ...createRoleColors('csc-color-1'),
  },
  CSCOrgUsageReportViewers: {
    name: 'Report Viewer',
    assignable: true,
    ...createRoleColors('csc-color-2'),
  },
  CSCOrgQvainMainUsers: {
    badge: 'Qvain',
    name: 'Main User',
    assignable: false,
    ...createRoleColors('csc-color-5'),
  },
};
