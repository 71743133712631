import type { Token } from '~/types/middleware';

export const useTokenStore = defineComposableStore(
  'token',
  () => {
    const token = ref('');

    const tokenUpdated = ref('');

    const authorizationCode = ref('');

    const decodedToken = ref<Token>({} as Token);

    return {
      tokenUpdated,
      token,
      decodedToken,
      authorizationCode,
    };
  },
  {
    persist: {
      paths: ['token', 'decodedToken', 'tokenUpdated', 'authorizationCode'],
    },
  },
);
