export const useSocket = <T>(
  callbackFn: (data: T) => void,
  options: {
    type: 'WF_COMPLETED' | 'INBOX';
  } = { type: 'WF_COMPLETED' },
) => {
  const config = useRuntimeConfig();

  const url = config.public.messageServiceWss;

  const { data, send } = useWebSocket<string>(url, {
    autoReconnect: {
      retries: 3,
      delay: 1000,
      onFailed() {
        console.log('Failed to connect WebSocket after 3 retries');
      },
    },
  });

  const subscribe = (requestId: string) => {
    send(
      JSON.stringify({
        message: 'subscribe',
        type: `${options.type}:${requestId}`,
      }),
    );
  };

  watch(data, async (d) => {
    if (!d) return;

    const response: T = JSON.parse(d);

    await callbackFn(response);
  });

  return { subscribe };
};
