import type { CToastMessage } from '@cscfi/csc-ui';

export const useNotificationStore = defineComposableStore(
  'notification',
  () => {
    const notifications = ref<Map<string, CToastMessage>>(new Map());

    const isVersionNotificationVisible = ref(false);

    const isCookieConsentVisible = ref(false);

    const showVersionNotification = () => {
      isVersionNotificationVisible.value = true;
    };

    const addNotification = (name: string, notification: CToastMessage) => {
      notifications.value.set(name, notification);
    };

    const removeNotification = (name: string) => {
      notifications.value.delete(name);
    };

    const cookieConsentOpen = () => {
      isCookieConsentVisible.value = true;
    };

    const cookieConsentClose = () => {
      isCookieConsentVisible.value = false;
    };

    return {
      addNotification,
      cookieConsentClose,
      cookieConsentOpen,
      showVersionNotification,
      removeNotification,
      isVersionNotificationVisible,
      notifications,
      isCookieConsentVisible,
    };
  },
);
