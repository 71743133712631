export default {
  quotaCannotBeLowerThanUsage:
    'The last quota application tried to lower quota below used quota, which is not allowed.',
  denied: 'The last quota application has been denied.',
  illegalValues: 'The last quota application had illegal values.',
  timedOut:
    'The last quota application timed out in 90 days, because it was not processed.',
  pending:
    'You have applied for a quota which exceeds the automatically granted values. Your application is pending for a review by CSC.',
};
