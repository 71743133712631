import type { DataPreloadHandler } from '~/types/middleware';

export const preloadCountries: DataPreloadHandler = async () => {
  const { countriesOfCitizenship, countriesOfResidence, getCountries } =
    useCountryStore();

  if (
    !countriesOfCitizenship.value?.length ||
    !countriesOfResidence.value?.length
  ) {
    await getCountries();
  }
};
