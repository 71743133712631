import { default as billing_45unit_45calculator63Qi7fkdhiMeta } from "/src/layers/billingUnitCalculator/pages/billing-unit-calculator.vue?macro=true";
import { default as bucLTc9EWYLedMeta } from "/src/layers/billingUnitCalculator/pages/buc.vue?macro=true";
import { default as index6laFbuQlKnMeta } from "/src/layers/contracts/pages/contracts/index.vue?macro=true";
import { default as csc_45customer_45registration7Yip8eiSNZMeta } from "/src/layers/authentication/pages/csc-customer-registration.vue?macro=true";
import { default as dashboardgn53HAnp7JMeta } from "/src/layers/dashboard/pages/dashboard.vue?macro=true";
import { default as _91_91token_93_93IJdHvuzfbSMeta } from "/src/layers/authentication/pages/forgot-password/[[token]].vue?macro=true";
import { default as funet_45role_45management6bVpf1IbxnMeta } from "/src/layers/organization/pages/funet-role-management.vue?macro=true";
import { default as ida_45adminYX5ialkNhRMeta } from "/src/layers/organization/pages/ida-admin.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as loginyUV4hdSe1mMeta } from "/src/layers/authentication/pages/login.vue?macro=true";
import { default as oidc_45loginyspB6sKZSNMeta } from "/src/layers/authentication/pages/oidc-login.vue?macro=true";
import { default as organization_45reportU5hAV2IyRZMeta } from "/src/layers/organization/pages/organization-report.vue?macro=true";
import { default as profileS4PfAaScMFMeta } from "/src/layers/profile/pages/profile.vue?macro=true";
import { default as project_45presentationsAP5OYUwFpoMeta } from "/src/layers/project/pages/project-presentations.vue?macro=true";
import { default as _91projectNumber_93N8MCKhwx0DMeta } from "/src/layers/project/pages/projects/[projectNumber].vue?macro=true";
import { default as createWwM9JcnN6uMeta } from "/src/layers/project/pages/projects/create.vue?macro=true";
import { default as indexqMFXA9coQSMeta } from "/src/layers/project/pages/projects/index.vue?macro=true";
import { default as _91hash_93ekExBvH32nMeta } from "/src/layers/project/pages/projects/invitation/[hash].vue?macro=true";
import { default as registrationCn7O2srX9rMeta } from "/src/layers/authentication/pages/registration.vue?macro=true";
import { default as reportslXQQrrkivLMeta } from "/src/layers/report/pages/reports.vue?macro=true";
import { default as token_45managementCO0zrKCeOcMeta } from "/src/layers/reppuApi/pages/reppu/token-management.vue?macro=true";
import { default as resource_45application_45managementK3TrT876z1Meta } from "/src/layers/resourceApplication/pages/resource-application-management.vue?macro=true";
import { default as role_45managementuuFO4ptrK0Meta } from "/src/layers/organization/pages/role-management.vue?macro=true";
import { default as service_45catalog8RSy7IPKTUMeta } from "/src/layers/service/pages/service-catalog.vue?macro=true";
import { default as userinfoMpv7rZd7GwMeta } from "/src/layers/profile/pages/userinfo.vue?macro=true";
import { default as welcomeDL3dunpDJCMeta } from "/src/layers/welcome/pages/welcome.vue?macro=true";
import { default as component_45stubgxFpV4faLuMeta } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubgxFpV4faLu } from "/src/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "BillingUnitCalculator",
    path: "/billing-unit-calculator",
    meta: billing_45unit_45calculator63Qi7fkdhiMeta || {},
    component: () => import("/src/layers/billingUnitCalculator/pages/billing-unit-calculator.vue")
  },
  {
    name: "Buc",
    path: "/buc",
    meta: bucLTc9EWYLedMeta || {},
    component: () => import("/src/layers/billingUnitCalculator/pages/buc.vue")
  },
  {
    name: "Contracts",
    path: "/contracts",
    meta: index6laFbuQlKnMeta || {},
    component: () => import("/src/layers/contracts/pages/contracts/index.vue")
  },
  {
    name: "CustomerRegistration",
    path: "/csc-customer-registration",
    meta: csc_45customer_45registration7Yip8eiSNZMeta || {},
    component: () => import("/src/layers/authentication/pages/csc-customer-registration.vue")
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    meta: dashboardgn53HAnp7JMeta || {},
    component: () => import("/src/layers/dashboard/pages/dashboard.vue")
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password/:token?",
    meta: _91_91token_93_93IJdHvuzfbSMeta || {},
    component: () => import("/src/layers/authentication/pages/forgot-password/[[token]].vue")
  },
  {
    name: "FunetRoleManagement",
    path: "/funet-role-management",
    meta: funet_45role_45management6bVpf1IbxnMeta || {},
    component: () => import("/src/layers/organization/pages/funet-role-management.vue")
  },
  {
    name: "IdaAdmin",
    path: "/ida-admin",
    meta: ida_45adminYX5ialkNhRMeta || {},
    component: () => import("/src/layers/organization/pages/ida-admin.vue")
  },
  {
    name: "Root",
    path: "/",
    meta: indexvsa0KwJWqiMeta || {},
    component: () => import("/src/pages/index.vue")
  },
  {
    name: "Login",
    path: "/login",
    meta: loginyUV4hdSe1mMeta || {},
    component: () => import("/src/layers/authentication/pages/login.vue")
  },
  {
    name: "OIDCLogin",
    path: "/oidc-login",
    meta: oidc_45loginyspB6sKZSNMeta || {},
    component: () => import("/src/layers/authentication/pages/oidc-login.vue")
  },
  {
    name: "organization-report",
    path: "/organization-report",
    meta: organization_45reportU5hAV2IyRZMeta || {},
    component: () => import("/src/layers/organization/pages/organization-report.vue")
  },
  {
    name: "Profile",
    path: "/profile",
    meta: profileS4PfAaScMFMeta || {},
    component: () => import("/src/layers/profile/pages/profile.vue")
  },
  {
    name: "project-presentations",
    path: "/project-presentations",
    meta: project_45presentationsAP5OYUwFpoMeta || {},
    component: () => import("/src/layers/project/pages/project-presentations.vue")
  },
  {
    name: "Project",
    path: "/projects/:projectNumber()",
    meta: _91projectNumber_93N8MCKhwx0DMeta || {},
    component: () => import("/src/layers/project/pages/projects/[projectNumber].vue")
  },
  {
    name: "ProjectCreation",
    path: "/projects/create",
    meta: createWwM9JcnN6uMeta || {},
    component: () => import("/src/layers/project/pages/projects/create.vue")
  },
  {
    name: "Projects",
    path: "/projects",
    meta: indexqMFXA9coQSMeta || {},
    component: () => import("/src/layers/project/pages/projects/index.vue")
  },
  {
    name: "ProjectInvitation",
    path: "/projects/invitation/:hash()",
    meta: _91hash_93ekExBvH32nMeta || {},
    component: () => import("/src/layers/project/pages/projects/invitation/[hash].vue")
  },
  {
    name: "HakaRegistration",
    path: "/registration",
    meta: registrationCn7O2srX9rMeta || {},
    component: () => import("/src/layers/authentication/pages/registration.vue")
  },
  {
    name: "Reports",
    path: "/reports",
    meta: reportslXQQrrkivLMeta || {},
    component: () => import("/src/layers/report/pages/reports.vue")
  },
  {
    name: "ReppuApiManagement",
    path: "/reppu/token-management",
    meta: token_45managementCO0zrKCeOcMeta || {},
    component: () => import("/src/layers/reppuApi/pages/reppu/token-management.vue")
  },
  {
    name: "ResourceApplicationManagement",
    path: "/resource-application-management",
    meta: resource_45application_45managementK3TrT876z1Meta || {},
    component: () => import("/src/layers/resourceApplication/pages/resource-application-management.vue")
  },
  {
    name: "RoleManagement",
    path: "/role-management",
    meta: role_45managementuuFO4ptrK0Meta || {},
    component: () => import("/src/layers/organization/pages/role-management.vue")
  },
  {
    name: "ServiceCatalog",
    path: "/service-catalog",
    meta: service_45catalog8RSy7IPKTUMeta || {},
    component: () => import("/src/layers/service/pages/service-catalog.vue")
  },
  {
    name: "Userinfo",
    path: "/userinfo",
    meta: userinfoMpv7rZd7GwMeta || {},
    component: () => import("/src/layers/profile/pages/userinfo.vue")
  },
  {
    name: "Welcome",
    path: "/welcome",
    meta: welcomeDL3dunpDJCMeta || {},
    component: () => import("/src/layers/welcome/pages/welcome.vue")
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/project-invitation/:pathMatch(.*)",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/myProjects",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/myProjects/project/:pathMatch(.*)",
    component: component_45stubgxFpV4faLu
  },
  {
    name: component_45stubgxFpV4faLuMeta?.name,
    path: "/forgotPassword/:pathMatch(.*)",
    component: component_45stubgxFpV4faLu
  }
]