import fundingTypes from '@/data/fundingTypes.json';

export const decodeFundingDecisions = (items: string[]) =>
  items.map((item) => {
    const [funderName, funderProjectNumber, typeOfFunding] = item.split('#');

    return {
      funderName,
      funderProjectNumber,
      typeOfFunding,
      typeOfFundingName:
        fundingTypes.find((type) => type.value === typeOfFunding)?.name ?? '',
    };
  });
