export const formatDate = (
  date: string | number,
  includeTime = false,
  locale = 'fi',
): string => {
  if (!date) return '';

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includeTime && {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }),
  };

  return new Intl.DateTimeFormat(locale, options)
    .format(new Date(date))
    .replace('klo', 'at');
};
