import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';
import type { SentryVitePluginOptions } from '@sentry/vite-plugin';
// import type { ViteSentryPluginOptions } from 'vite-plugin-sentry';

export const createSentryInstance = (app: App, router: Router, dsn: string) => {
  Sentry.init({
    app,
    dsn,
    environment: 'development',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    tracePropagationTargets: ['localhost', 'https://my.csc.fi'],

    tracesSampleRate: 0.2,
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
  });
};

export const createSentryPluginConfig = (authToken: string) => {
  const sentryConfig: SentryVitePluginOptions = {
    url: 'https://mycsc-sentry.csc.fi',
    authToken,
    org: 'sentry',
    project: 'my-csc-nuxt',
    release: {
      name: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
      create: true,
      deploy: {
        env: 'production',
      },
      setCommits: {
        ignoreMissing: true,
        auto: true,
      },
    },
    sourcemaps: { disable: true },
  };

  return sentryConfig;
};
