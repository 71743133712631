import { convertLDAPDate } from './convertLDAPDate';

export const formatLDAPDate = (
  ldapString: string,
  format = 'fi-FI',
  includeTime = false,
): string => {
  if (!ldapString) return '';

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...(includeTime && {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    }),
  };

  return new Intl.DateTimeFormat(format, options).format(
    convertLDAPDate(ldapString),
  );
};
