import type { RouteLocationNormalized } from 'vue-router';
import * as dataPreloadHandlers from './handlers';
import type { DataPreloadHandlers } from '~/types/middleware';

export const handleDataPreload = async (to: RouteLocationNormalized) => {
  const promises = (
    (to.meta.dataPreloaders || []) as (keyof DataPreloadHandlers)[]
  ).map((data) =>
    (dataPreloadHandlers as DataPreloadHandlers)[
      data as keyof DataPreloadHandlers
    ](to),
  );

  await Promise.all(promises);
};
