import { create, test, enforce, only } from 'vest';
import {
  isRequiredMessage,
  isRequiredTrueMessage,
} from '@/utils/validationMessages';

export const projectGDPRFormSuite = create((data, name?: string) => {
  only(name);

  test('confirmation', isRequiredTrueMessage(), () => {
    enforce(data.confirmation).equals(true);
  });

  test('dataController', isRequiredMessage(), () => {
    enforce(data.dataController).isNotBlank();
  });
});

export default projectGDPRFormSuite;
