<template>
  <div
    class="fixed left-0 top-0 z-50 grid size-full place-content-center bg-white"
  >
    <div class="grid w-32 place-items-center gap-2">
      <div class="mb-2">
        <c-spinner :size="64" :width="4" />
      </div>

      <p class="text-center uppercase">
        <slot name="title" />
      </p>

      <p class="whitespace-nowrap text-center text-sm text-gray-400">
        <slot name="subtitle" />
      </p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
c-spinner {
  animation: colorChange 10s infinite 0.8s;
}

@keyframes colorChange {
  0% {
    --c-color: var(--c-primary-600);
  }

  25% {
    --c-color: var(--c-secondary-600);
  }

  50% {
    --c-color: var(--c-info-600);
  }

  75% {
    --c-color: var(--c-link-600);
  }
}
</style>
