import type { Project } from '@/types/project';
import { ProjectTypes } from '@/types/enum';

export const getProjectType = (project: Project) => {
  if (project.CSCPrjType === 'fmi') return ProjectTypes.Fmi;

  if (
    project.CSCPrjType === 'findata' ||
    project.CSCPrjType?.includes('findata')
  )
    return ProjectTypes.Findata;

  if (project.CSCPrjType === 'course') {
    return project.CSCPrjScope === 'internal'
      ? ProjectTypes.CourseInternal
      : ProjectTypes.Course;
  }

  if (project.CSCPrjType === 'student') {
    return ProjectTypes.Student;
  }

  const type =
    (toTitleCase(project.CSCPrjScope) as ProjectTypes) || ProjectTypes.Unknown;

  return type;
};
