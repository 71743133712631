import { create, test, enforce, only } from 'vest';
import { isRequiredTrueMessage } from '@/utils/validationMessages';

export const projectCloseSuite = create((data, name?: string) => {
  only(name);

  test('consentToProceed', isRequiredTrueMessage(), () => {
    enforce(data.consentToProceed).equals(true);
  });
});

export default projectCloseSuite;
