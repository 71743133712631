<template>
  <div class="grid place-content-center h-screen w-screen gap-4">
    <component :is="errorComponent" :error />
  </div>
</template>

<script setup lang="ts">
import { defineCustomElements } from '@cscfi/csc-ui/loader';
import type { NuxtError } from '#app';

const props = defineProps<{
  error?: NuxtError;
}>();

defineCustomElements();

const errorComponent = computed(() => {
  const components = {
    404: defineAsyncComponent(() => import(`@/components/error/Error404.vue`)),
    401: defineAsyncComponent(() => import(`@/components/error/Error401.vue`)),
  };

  const defaultErrorComponent = defineAsyncComponent(
    () => import(`@/components/error/Error504.vue`),
  );

  return (
    components[props.error?.statusCode as keyof typeof components] ||
    defaultErrorComponent
  );
});
</script>
