import validate from "/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/src/middleware/auth.global.ts";
import cookie_45consent_45global from "/src/middleware/cookieConsent.global.ts";
import manifest_45route_45rule from "/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  cookie_45consent_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "manager-status": () => import("/src/layers/project/middleware/managerStatus.ts"),
  project: () => import("/src/layers/project/middleware/project.ts"),
  projects: () => import("/src/layers/project/middleware/projects.ts"),
  country: () => import("/src/layers/profile/middleware/country.ts"),
  profile: () => import("/src/layers/profile/middleware/profile.ts"),
  "require-mfa": () => import("/src/layers/organization/middleware/requireMFA.ts"),
  "handle-project-population": () => import("/src/middleware/handleProjectPopulation.ts"),
  root: () => import("/src/middleware/root.ts")
}