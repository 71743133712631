const reasons = {
  'password.cannot.contain.first.name': 'Password can not contain your name',
  'password.cannot.contain.last.name': 'Password can not contain your name',
  userTypeNotAllowed:
    'Password change is not allowed for your user account type. Please contact servicedesk@csc.fi',
  wrongPassword: 'The provided current password was wrong.',
  'forgot.password.link.expired':
    'Your password reset link has expired. Please order a new link.',
  'login.disabled':
    'Your password can not be changed. Your CSC account is locked. Please contact servicedesk@csc.fi to unlock it.',
};

export const codes = {
  ...reasons,
  '-215': 'Previously used password not allowed',
  '-216': 'Password too short',
  '-16000':
    'The password change or set request failed because the password is longer than allowed by the password policy.',
  '-16001':
    'The password change or set request failed because the password does not contain the minimum number of uppercase characters required by the password policy.',
  '-16002':
    'The password change or set request failed because the password contains more than the maximum number of uppercase characters allowed by the password policy.',
  '-16003':
    'The password change or set request failed because the password does not contain the minimum number of lowercase characters required by the password policy.',
  '-16004':
    'The password change or set request failed because the password contains more than the maximum number of lowercase characters allowed by the password policy.',
  '-16005':
    'The password change or set request failed because the password contains numeric characters that are disallowed by the password policy.',
  '-16006':
    'The password change or set request failed because the first character of the password is a numeric character that is disallowed by the password policy.',
  '-16007':
    'The password change or set request failed because the last character of the password is a numeric character that is disallowed by the password policy.',
  '-16008':
    'The password change or set request failed because the password does not contain the minimum number of numeric characters required by the password policy.',
  '-16009':
    'The password change or set request failed because the password contains more than the maximum number of numeric case characters allowed by the password policy.',
  '-16010':
    'The password change or set request failed because the password contains non-alphanumeric characters that are disallowed by the password policy.',
  '-16011':
    'The password change or set request failed because the first character of the password is a non-alphanumeric character that is disallowed by the password policy.',
  '-16012':
    'The password change or set request failed because the last character of the password is a non-alphanumeric character that is disallowed by the password policy.',
  '-16013':
    'The password change or set request failed because the password does not contain the minimum number of non-alphanumeric characters required by the password policy.',
  '-16014':
    'The password change or set request failed because the password contains more than the maximum number of non-alphanumeric case characters allowed by the password policy.',
  '-16015':
    'The password change or set request failed because the password contains a character that appears in the password more times than is allowed by the password policy.',
  '-16016':
    'The password change or set request failed because the password contains a character that appears consecutively more times than is allowed by the password policy.',
  '-16017':
    'The password change or set request failed because the password does not contain the minimum number of unique characters required by the password policy.',
  '-16018':
    'The password change or set request failed because the minimum amount of time since the last successful password change as required by the password policy has not elapsed.',
  '-16019':
    'The password change or set request failed because the password appears in the excluded password list of the password policy.',
  '-16020':
    'The password change or set request failed because the password is the same as a value of a user attribute that is disallowed by the password policy.',
  '-16021':
    'The password change or set request failed because the password contains extended characters that are disallowed by the password policy.',
};

export const getLDAPErrorMessage = (code: string) =>
  codes[code as keyof typeof codes] || 'Unknown error occurred';
