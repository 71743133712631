import type { NitroFetchRequest } from 'nitropack';
import type { H3Event, EventHandlerRequest } from 'h3';

/**
 * Fetches data from the specified URL with authentication.
 *
 * @param {NitroFetchRequest} url - The URL to fetch data from.
 * @param {Record<string, unknown>} [opts] - Optional request options.
 * @param {AbortController} [abortController] - Optional AbortController instance.
 * @param {H3Event<EventHandlerRequest>} [event] - Optional event object.
 * @return {Promise<T>} A promise that resolves to the fetched data.
 */
const authenticatedFetch = <T>(
  url: NitroFetchRequest,
  opts?: Record<string, unknown>,
  abortController?: AbortController,
  event?: H3Event<EventHandlerRequest>,
) => {
  const { token } = event
    ? (JSON.parse((getCookie(event, 'token') as string) || '{"token": ""}') as {
        token: string;
      })
    : useTokenStore();

  const authToken = event ? token : (token as Ref<string>)?.value;

  return $fetch<T>(url, {
    ...(opts || {}),

    headers: {
      ...(opts?.headers ? opts.headers : {}),
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    },

    credentials: 'include',

    ...(abortController ? { signal: abortController.signal } : {}),

    onResponseError: ({ response }) => {
      console.error(response);
    },
  });
};

/**
 * Creates a set of HTTP request methods including POST, GET, DELETE, and PUT.
 *
 * @param {H3Event<EventHandlerRequest>} event - Optional event object.
 * @return {Object} An object containing post, get, delete, and put methods for making HTTP requests.
 */
export const useApi = (event?: H3Event<EventHandlerRequest>) => {
  const post = <T, U = unknown>(
    url: NitroFetchRequest,
    body?: T,
    config?: RequestInit,
    abortController?: AbortController,
  ): Promise<U> =>
    authenticatedFetch<U>(
      url,
      { method: 'POST', body, ...config },
      abortController,
      event,
    );

  const get = <U = unknown>(
    url: NitroFetchRequest,
    config?: RequestInit,
    abortController?: AbortController,
  ): Promise<U> =>
    authenticatedFetch<U>(url, { ...config }, abortController, event);

  const deleteRequest = <T, U = unknown>(
    url: NitroFetchRequest,
    body?: T,
    config?: RequestInit,
    abortController?: AbortController,
  ): Promise<U> =>
    authenticatedFetch<U>(
      url,
      { method: 'DELETE', body, ...config },
      abortController,
      event,
    );

  const put = <T, U = unknown>(
    url: NitroFetchRequest,
    body?: T,
    config?: RequestInit,
    abortController?: AbortController,
  ): Promise<U> =>
    authenticatedFetch<U>(
      url,
      { method: 'PUT', body, ...config },
      abortController,
      event,
    );

  return { post, get, delete: deleteRequest, put };
};

export const api = useApi();
