import { create, test, enforce, only, omitWhen } from 'vest';
import { isRequiredMessage } from '@/utils/validationMessages';

export const projectEditSuite = create((data, name?: string) => {
  only(name);

  test('title', isRequiredMessage(), () => {
    enforce(data.title).isNotBlank();
  });

  test('description', isRequiredMessage(), () => {
    enforce(data.description).isNotBlank();
  });

  omitWhen(!data.isInternal, () => {
    test('costObject', isRequiredMessage(), () => {
      enforce(data.costObject).isNotBlank();
    });
  });
});

export default projectEditSuite;
