import type { CSelectItem } from '@cscfi/csc-ui';
import gql from 'graphql-tag';
import type { DataResponse, GenericWorkflowResponse } from '@/types';
import { organizationRoles } from '@/utils/roles';
import type { Organization } from '~/types/profile';
import type { OrganizationDataset } from '~/types/organization';

type User = {
  dn: string;
  mobile: string;
  mail: string;
  fullname: string;
  cn: string;
  roles: string[];
};

export const useOrganizationStore = defineComposableStore(
  'organization',
  () => {
    const selectedOrganization = ref<CSelectItem>();

    const users = ref<User[]>([]);

    const funetUsers = ref<User[]>([]);

    const datasets = ref<OrganizationDataset>({} as OrganizationDataset);

    const roles = organizationRoles;

    const { startLoading, endLoading } = useLoadingStore();

    const getDatasets = async (organizationDn: string, year: string) => {
      startLoading('getDatasets');

      const { response: gqlResponse, query, error } = useGqlApi();

      const datasetsQuery = gql`
        query OrganizationDatasets($year: String!, $organizationId: String!) {
          organizationDatasets(year: $year, organizationId: $organizationId) {
            ida_datasets
            remote_datasets
            harvested_datasets
            digital_preservation_datasets
            ida_tib
            pas_tib
            allas_tib
          }
        }
      `;

      await query(datasetsQuery, { organizationId: organizationDn, year });

      if (error.value) {
        endLoading('getDatasets');

        return;
      }

      datasets.value = gqlResponse.value?.organizationDatasets;

      endLoading('getDatasets');
    };

    const listUsers = async (organizationDn: string) => {
      startLoading('listUsers');

      users.value = [];

      const { success, data } = await api.get<DataResponse<User[]>>(
        `/api/organization/users/${organizationDn}`,
      );

      if (!success) {
        endLoading('listUsers');

        return;
      }

      users.value = data!;

      endLoading('listUsers');
    };

    const listFunetUsers = async (organizationDn: string) => {
      startLoading('listFunetUsers');

      funetUsers.value = [];

      const { success, data } = await api.get<DataResponse<User[]>>(
        `/api/organization/funet-users/${organizationDn}`,
      );

      if (!success) {
        endLoading('listFunetUsers');

        return;
      }

      funetUsers.value = data!;

      endLoading('listFunetUsers');
    };

    const getRole = (role: keyof typeof roles) =>
      roles[role] || { name: role, color: 'var(--c-primary-600)' };

    const updateUserRoles = async (
      newRoles: string[],
      userDn: string,
      organizationDn: string,
      funet = false,
    ) => {
      startLoading('updateUserRoles');

      const endpoint = funet ? 'funet' : 'default';

      const { success } = await api.post<
        {
          userDn: string;
          roles: string[];
          organizationDn: string;
        },
        GenericWorkflowResponse
      >(`/api/organization/roles/${endpoint}`, {
        userDn,
        roles: newRoles,
        organizationDn,
      });

      if (!success) {
        endLoading('updateUserRoles');

        return;
      }

      const items = funet ? funetUsers : users;

      items.value = [
        ...items.value.map((user) => ({
          ...user,
          roles: user.dn === userDn ? newRoles : user.roles,
        })),
      ];

      endLoading('updateUserRoles');
    };

    const organization = ref<Organization>({} as Organization);

    const getOrganization = async (organzationCn: string) => {
      startLoading('getOrganization');

      const { success, data } = await api.get<DataResponse<Organization>>(
        `/api/organization/${organzationCn}`,
      );

      if (!success) {
        organization.value = {} as Organization;

        endLoading('getOrganization');

        return;
      }

      organization.value = data!;

      endLoading('getOrganization');
    };

    return {
      selectedOrganization,
      users,
      roles,
      funetUsers,
      organization,
      datasets,
      listUsers,
      listFunetUsers,
      getDatasets,
      getRole,
      updateUserRoles,
      getOrganization,
    };
  },
);
