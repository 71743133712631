import revive_payload_client_4sVQNw7RlN from "/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/src/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import control_client_p4yQLeQA58 from "/src/plugins/control.client.ts";
import error_ldt3PQauZ0 from "/src/plugins/error.ts";
import resetStore_YYP4aTXUos from "/src/plugins/resetStore.ts";
import sentry_client_shVUlIjFLk from "/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  plugin_AUP22rrBAc,
  control_client_p4yQLeQA58,
  error_ldt3PQauZ0,
  resetStore_YYP4aTXUos,
  sentry_client_shVUlIjFLk
]