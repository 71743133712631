import { CToastType } from '@cscfi/csc-ui';
import type {
  MembershipApplicationAcceptParams,
  MembershipApplicationDeleteParams,
} from '../schemas/membershipApplication.schema';
import type {
  AddMembersBody,
  RemoveMembersBody,
} from '../schemas/members.schema';
import type {
  DeleteInvitationLinkBody,
  GenerateInvitationLinkBody,
} from '../schemas/invitationLink.schema';
import type {
  Application,
  InvitationLinkGenerationResponse,
  Member,
  User,
} from '@/types/project';
import type { DataResponse, GenericWorkflowResponse } from '@/types';
import type { DataResponseType } from '~/types/response';

export const useMemberStore = defineComposableStore('member', () => {
  const { startLoading, endLoading } = useLoadingStore();

  const { addNotification } = useNotificationStore();

  const { project } = useProjectStore();

  const route = useRoute();

  const router = useRouter();

  const projectMembers = ref<Member[]>([]);

  const membershipApplications = ref<Application[]>([]);

  const membershipApplicationsForManager = ref<Application[]>([]);

  const invitationLink = ref<string>('');

  const handleSocketResponse = async (response: {
    status: string;
    data: { action: string };
  }) => {
    if (response.status === 'finished') {
      await pause(2000);
      await getProjectMembers(route.params.projectNumber as string);
      await getMembershipApplications(route.params.projectNumber as string);
      endLoading('addProjectMembers');
      endLoading('acceptMembershipApplications');
      endLoading('members');
    }
  };

  const acceptMembershipApplications = async (
    payload: MembershipApplicationAcceptParams,
  ) => {
    startLoading('acceptMembershipApplications');

    const { subscribe } = useSocket<{
      status: string;
      data: { action: string };
    }>(handleSocketResponse);

    const { success, data } = await api.post<
      MembershipApplicationAcceptParams,
      GenericWorkflowResponse
    >('/api/project/membership-application/accept', payload);

    if (!success) {
      endLoading('acceptMembershipApplications');

      return;
    }

    if (data.reqId) {
      subscribe(data.reqId);
    }

    addNotification(`acceptMembershipApplications`, {
      message: `${payload.members.length} application${
        payload.members.length === 1 ? '' : 's'
      } approved`,
      type: CToastType.Success,
    });
  };

  const denyMembershipApplications = async (
    payload: MembershipApplicationDeleteParams,
  ) => {
    startLoading('denyMembershipApplications');

    const { projectNumber, members } = payload;

    const { success } = await api.delete<
      MembershipApplicationDeleteParams,
      GenericWorkflowResponse
    >('/api/project/membership-application', {
      projectNumber,
      members,
    });

    if (!success) {
      return;
    }

    await getMembershipApplications(route.params.projectNumber as string);

    addNotification(`denyMembershipApplications`, {
      message: `${payload.members.length} application${
        payload.members.length === 1 ? '' : 's'
      } denied`,
      type: CToastType.Success,
    });

    endLoading('denyMembershipApplications');
  };

  const addProjectMembers = async (members: string[]) => {
    startLoading('addProjectMembers');
    startLoading('members');

    const { subscribe } = useSocket<{
      status: string;
      data: { action: string };
    }>(handleSocketResponse);

    const { success, data } = await api.post<
      AddMembersBody,
      GenericWorkflowResponse
    >('/api/project/members', {
      members,
      projectNumber: project.value?.CSCPrjNum,
    });

    if (!success) {
      endLoading('addProjectMembers');
      endLoading('members');

      return;
    }

    if (data.reqId) {
      subscribe(data.reqId);
    }
  };

  const applyForMembership = async (projectNumber: string) => {
    startLoading('applyForMembership');

    const { success, data } = await api.post<
      { hash: string; projectNumber: string },
      DataResponse<{ status: 'ok' | 'application-exists' }>
    >('/api/project/membership-application', {
      hash: route.params.hash as string,
      projectNumber,
    });

    if (!success) {
      endLoading('applyForMembership');

      return;
    }

    if (data?.status === 'application-exists') {
      addNotification(`applyForMembership`, {
        title: 'Duplicate application',
        message:
          'You have already applied for a membership in this project and your application is pending approval by the project manager.',
        type: CToastType.Info,
        persistent: true,
      });

      endLoading('applyForMembership');

      return;
    }

    addNotification(`applyForMembership`, {
      title: 'Application submitted successfully',
      message: 'Your application is pending approval by the project manager.',
      type: CToastType.Success,
    });

    router.push({ name: 'Projects' });

    endLoading('applyForMembership');
  };

  const deleteInvitationLink = async () => {
    startLoading('deleteInvitationLink');

    await nextTick();

    const { success } = await api.delete<
      DeleteInvitationLinkBody,
      DataResponseType<null>
    >('/api/project/invitation-link', {
      projectNumber: route.params.projectNumber as string,
    });

    if (!success) {
      endLoading('deleteInvitationLink');

      return;
    }

    invitationLink.value = '';

    endLoading('deleteInvitationLink');
  };

  const generateInvitationLink = async () => {
    startLoading('generateInvitationLink');

    const { success, data } = await api.post<
      GenerateInvitationLinkBody,
      DataResponseType<InvitationLinkGenerationResponse>
    >('/api/project/invitation-link', {
      projectNumber: project.value?.CSCPrjNum,
    });

    if (!success) {
      endLoading('generateInvitationLink');

      return;
    }

    if (data?.hash) {
      invitationLink.value = `${window.location.origin}/project-invitation/${data.hash}`;
    }

    endLoading('generateInvitationLink');
  };

  const getInvitationLink = async (projectNumber: string) => {
    startLoading('getInvitationLink');

    const { data } = await api.get<
      DataResponseType<InvitationLinkGenerationResponse>
    >(`/api/project/invitation-link/${projectNumber}`);

    invitationLink.value = '';

    if (data?.hash) {
      invitationLink.value = `${window.location.origin}/project-invitation/${data.hash}`;
    }

    endLoading('getInvitationLink');
  };

  const getMembershipApplications = async (projectNumber: string) => {
    if (!project.value.isProjectManager) return;

    startLoading('getMembershipApplications');

    const { success, data } = await api.get<DataResponseType<Application[]>>(
      `/api/project/membership-application/${projectNumber}`,
    );

    if (success) {
      membershipApplications.value = (data || []).map((application) => ({
        ...application,
        selected: false,
      }));
    }

    endLoading('getMembershipApplications');
  };

  const getMembershipApplicationsForManager = async () => {
    startLoading('getMembershipApplicationsForManager');

    const { success, data } = await api.get<DataResponseType<Application[]>>(
      '/api/project/membership-application',
    );

    if (success && data) {
      membershipApplicationsForManager.value = data;
    }

    endLoading('getMembershipApplicationsForManager');
  };

  const getProjectMembers = async (projectNumber: string) => {
    try {
      startLoading('members');

      projectMembers.value = [];

      const { data } = await api.get<DataResponse<Member[]>>(
        `/api/project/members/${projectNumber}`,
      );

      projectMembers.value = data || ([] as Member[]);

      endLoading('members');
    } catch (error) {
      endLoading('members');
    }
  };

  const queryMembers = async (query = '') => {
    if (query.length < 3) return [];

    startLoading('queryMembers');

    const { data } = await api.get<DataResponse<User[]>>(
      `/api/organization/members/${query}`,
    );

    endLoading('queryMembers');

    return data || ([] as User[]);
  };

  const removeProjectMembers = async (members: string[]) => {
    startLoading('members');
    startLoading('removeProjectMembers');

    const { subscribe } = useSocket<{
      status: string;
      data: { action: string };
    }>(handleSocketResponse);

    const { data } = await api.delete<
      RemoveMembersBody,
      GenericWorkflowResponse
    >('/api/project/members', {
      members,
      projectNumber: project.value?.CSCPrjNum,
    });

    if (data.reqId) {
      subscribe(data.reqId);
    }

    endLoading('removeProjectMembers');
  };

  return {
    invitationLink,
    membershipApplications,
    membershipApplicationsForManager,
    projectMembers,
    acceptMembershipApplications,
    addProjectMembers,
    deleteInvitationLink,
    denyMembershipApplications,
    generateInvitationLink,
    getMembershipApplications,
    getMembershipApplicationsForManager,
    getProjectMembers,
    queryMembers,
    removeProjectMembers,
    getInvitationLink,
    applyForMembership,
  };
});
