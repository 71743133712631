import type { CSelectItem } from '@cscfi/csc-ui';
import type { Display } from '@/types/mappingJSON';
import type { JsonMapItem } from '~/layers/ldap/types';
import type { DataResponseType } from '~/types/response';

export const useCountryStore = defineComposableStore('country', () => {
  const countriesOfCitizenship = ref<CSelectItem[]>([]);

  const countriesOfResidence = ref<CSelectItem[]>([]);

  const { startLoading, endLoading } = useLoadingStore();

  const getCountries = async () => {
    if (countriesOfCitizenship.value.length) return;

    startLoading('getCountries');

    try {
      const { data } = await api.get<DataResponseType<JsonMapItem[]>>(
        '/api/ldap/json-map?list=schacCountryOfCitizenShip',
      );

      if (data?.length) {
        for (const country of data.sort((a: JsonMapItem, b: JsonMapItem) =>
          (a.display as Display).label.localeCompare(
            (b.display as Display).label,
            'fi',
          ),
        )) {
          countriesOfCitizenship.value.push({
            value: country.key,
            name: (country.display as Display).label,
          });

          countriesOfResidence.value.push({
            value: (country.display as Display).label,
            name: (country.display as Display).label,
          });
        }
      }
    } catch (error) {
      console.error('getCountries', error);
    }

    endLoading('getCountries');
  };

  return { countriesOfCitizenship, countriesOfResidence, getCountries };
});
