import { create, test, enforce, omitWhen } from 'vest';
import {
  isRequiredMessage,
  isLessThanOrEqualsMessage,
  isGreaterThanOrEqualsMessage,
} from '@/utils/validationMessages';

enforce.extend({
  justificationRequired(data) {
    return {
      pass:
        data.diskSpace <= data.justificationLimits.diskSpace &&
        data.numberOfFiles <= data.justificationLimits.numberOfFiles &&
        data.projAppDiskSpace <= data.justificationLimits.projAppDiskSpace &&
        data.projAppNumberOfFiles <=
          data.justificationLimits.projAppNumberOfFiles,
      message: '',
    };
  },
});
export const mahtiQuotaSuite = create((data = {}) => {
  omitWhen(!data.requireJustification, () => {
    test('justification', isRequiredMessage(), () => {
      enforce(data.justification).isNotBlank();
    });
  });

  test('diskSpace', isRequiredMessage(), () => {
    enforce(data.diskSpace).isNotBlank();
  });

  test('diskSpace', () => {
    enforce(data.diskSpace)
      .message(isGreaterThanOrEqualsMessage(data.defaults.scratchDefaultQuota))
      .greaterThanOrEquals(data.defaults.scratchDefaultQuota)
      .message(isLessThanOrEqualsMessage(data.maxQuota.diskSpace))
      .lessThanOrEquals(data.maxQuota.diskSpace);
  });

  test('numberOfFiles', isRequiredMessage(), () => {
    enforce(data.numberOfFiles).isNotBlank();
  });

  test('numberOfFiles', () => {
    enforce(data.numberOfFiles)
      .message(
        isGreaterThanOrEqualsMessage(data.defaults.scratchDefaultFileCount),
      )
      .greaterThanOrEquals(data.defaults.scratchDefaultFileCount)
      .message(isLessThanOrEqualsMessage(data.maxQuota.numberOfFiles))
      .lessThanOrEquals(data.maxQuota.numberOfFiles);
  });

  test('projAppDiskSpace', isRequiredMessage(), () => {
    enforce(data.projAppDiskSpace).isNotBlank();
  });

  test('projAppDiskSpace', () => {
    enforce(data.projAppDiskSpace)
      .message(isGreaterThanOrEqualsMessage(data.defaults.projApplDefaultQuota))
      .greaterThanOrEquals(data.defaults.projApplDefaultQuota)
      .message(isLessThanOrEqualsMessage(data.maxQuota.projAppDiskSpace))
      .lessThanOrEquals(data.maxQuota.projAppDiskSpace);
  });

  test('projAppNumberOfFiles', isRequiredMessage(), () => {
    enforce(data.projAppNumberOfFiles).isNotBlank();
  });

  test('projAppNumberOfFiles', () => {
    enforce(data.projAppNumberOfFiles)
      .message(
        isGreaterThanOrEqualsMessage(data.defaults.projApplDefaultFileCount),
      )
      .greaterThanOrEquals(data.defaults.projApplDefaultFileCount)
      .message(isLessThanOrEqualsMessage(data.maxQuota.projAppNumberOfFiles))
      .lessThanOrEquals(data.maxQuota.projAppNumberOfFiles);
  });
});

export default mahtiQuotaSuite;
