import { create, test, enforce, only, omitWhen } from 'vest';
import {
  isRequiredMessage,
  isValidEmailMessage,
  isPositiveMessage,
} from '@/utils/validationMessages';

export const reppuTokenFormSuite = create((data, name?: string) => {
  only(name);

  test('requestorName', isRequiredMessage(), () => {
    enforce(data.requestor.name).isNotBlank();
  });

  test('requestorEmail', isRequiredMessage(), () => {
    enforce(data.requestor.email).isNotBlank();
  });

  test('requestorEmail', isValidEmailMessage(), () => {
    enforce(data.requestor.email).matches(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
    );
  });

  test('roles', isRequiredMessage(), () => {
    enforce(data.roles).isArray().lengthNotEquals(0);
  });

  omitWhen(!data.restrictToOrganization, () => {
    test('organizationCn', isRequiredMessage(), () => {
      enforce(data.organizationCn).isNotBlank();
    });
  });

  omitWhen(data.expirationType === 'never', () => {
    test('expirationTime', isPositiveMessage(), () => {
      enforce(data.expiration.time).isPositive();
    });
  });
});

export default reppuTokenFormSuite;
