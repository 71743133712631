import type { MessageStatus } from '../schemas/inbox';
import type {
  MailboxMessage,
  MailboxResponse,
  InboxMessages,
} from '@/types/mailbox';
import type { DataResponseType } from '~/types/response';

const objectify = (items: MailboxMessage[]) =>
  items.reduce((messages, message) => {
    messages[message.id] = message;

    return messages;
  }, {} as InboxMessages);

export const useMailboxStore = defineComposableStore('mailbox', () => {
  const inboxMessages = ref<InboxMessages>({});

  const total = ref(0);

  const unread = ref(0);

  const currentPage = ref(0);

  const currentOffset = ref(0);

  const { startLoading, endLoading, isLoading } = useLoadingStore();

  const messages = computed(() => {
    const startFrom = currentPage.value * currentOffset.value;
    const endTo = startFrom + currentOffset.value;

    return Object.values(inboxMessages.value).slice(startFrom, endTo);
  });

  const getCurrentMessageIndex = (messageId: string) =>
    Object.keys(inboxMessages.value).findIndex((id) => id === messageId);

  const getNextMessage = async (messageId: string) => {
    const index = getCurrentMessageIndex(messageId);

    const fetchedMessage = Object.values(inboxMessages.value)[index + 1];

    // return from store
    if (fetchedMessage) return fetchedMessage;

    // last page -> return current
    if (total.value === index + 1) return inboxMessages.value[messageId];

    // get next messages and return next
    await getMessages(currentPage.value + 1, currentOffset.value);

    return Object.values(inboxMessages.value)[index + 1];
  };

  const getPreviousMessage = (messageId: string) => {
    const index = Object.keys(inboxMessages.value).findIndex(
      (id) => id === messageId,
    );

    if (index > 0) {
      return Object.values(inboxMessages.value)[index - 1];
    }

    return Object.values(inboxMessages.value)[Math.max(0, index)];
  };

  const getMessages = async (page: number = 0, offset: number = 25) => {
    if (isLoading('getMailboxMessages')) return;

    startLoading('getMailboxMessages');

    const { success, data } = await api.get<DataResponseType<MailboxResponse>>(
      `/api/message/${offset}/${page}`,
    );

    if (!success) {
      endLoading('getMailboxMessages');

      return;
    }

    currentOffset.value = offset;
    currentPage.value = page;

    const sortedMessages = [
      ...Object.values(inboxMessages.value),
      ...(data?.messages || []).map((message) => ({
        ...message,
        day: formatDate(message.date),
        isOpen: false,
      })),
    ].sort((a: MailboxMessage, b: MailboxMessage) =>
      b.date.localeCompare(a.date, 'fi'),
    );

    total.value = data?.total || 0;
    unread.value = data?.unread || 0;

    inboxMessages.value = objectify(sortedMessages);

    endLoading('getMailboxMessages');
  };

  const markMessageAsRead = async (messageId: string) => {
    const message = messages.value.find((msg) => msg.id === messageId);

    if (!message || message.status === 'READ' || !messageId) return;

    const { success } = await api.put<
      { messageId: string; status: MessageStatus },
      { success: boolean }
    >(`/api/message`, { messageId, status: 'READ' });

    if (!success) {
      return;
    }

    message.status = 'READ';
    unread.value -= 1;
  };

  const deleteMessage = async (messageId: string) => {
    startLoading('deleteMessage');

    const { success } = await api.delete<
      { messageId: string },
      { success: boolean }
    >('/api/message', { messageId });

    if (!success) {
      endLoading('deleteMessage');

      return;
    }

    total.value -= 1;

    inboxMessages.value = objectify(
      Object.values(inboxMessages.value).filter((msg) => msg.id !== messageId),
    );

    endLoading('deleteMessage');
  };

  return {
    deleteMessage,
    getCurrentMessageIndex,
    getMessages,
    getNextMessage,
    getPreviousMessage,
    markMessageAsRead,
    currentOffset,
    currentPage,
    inboxMessages,
    messages,
    total,
    unread,
  };
});
