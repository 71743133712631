import type { RouteLocationNormalized } from 'vue-router';

export const handleProjectNumber = (to: RouteLocationNormalized) => {
  if (!to.params.projectNumber) return;

  const { currentProjectNumber } = useProjectStore();

  currentProjectNumber.value = to.params.projectNumber as string;

  const { getProjectStats } = useResourceUsageStore();

  getProjectStats([
    'projectUsage',
    'idaUsage',
    'billingUnitsByService',
    'parsedStorageUsage',
  ]);
};
